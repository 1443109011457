import { graphql, useStaticQuery } from "gatsby"
import { ImageDataLike } from "gatsby-plugin-image"

const cardImageQuery = graphql`
  query getCardSamples {
    allFile(
      filter: {
        sourceInstanceName: { eq: "images" }
        relativeDirectory: { eq: "カードサンプル" }
      }
    ) {
      nodes {
        relativePath
        childImageSharp {
          gatsbyImageData(height: 500, formats: [AUTO, WEBP, AVIF])
        }
      }
    }
  }
`

export const useCardSamples = (): ImageDataLike[] => {
  return useStaticQuery(cardImageQuery).allFile.nodes
}
