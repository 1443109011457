import { Disclosure, Transition } from "@headlessui/react"
import { BiArrowToTop } from "@react-icons/all-files/bi/BiArrowToTop"
import { FaAngleLeft } from "@react-icons/all-files/fa/FaAngleLeft"
import { FaAngleRight } from "@react-icons/all-files/fa/FaAngleRight"
import { FaPenNib } from "@react-icons/all-files/fa/FaPenNib"
import { ImFacebook } from "@react-icons/all-files/im/ImFacebook"
import { IoLogoTwitter } from "@react-icons/all-files/io/IoLogoTwitter"
import { RiLineFill } from "@react-icons/all-files/ri/RiLineFill"
import { graphql, useStaticQuery } from "gatsby"
import {
  GatsbyImage,
  getImage,
  IGatsbyImageData,
  StaticImage,
} from "gatsby-plugin-image"
import scrollTo from "gatsby-plugin-smoothscroll"
import React, {
  BaseHTMLAttributes,
  FC,
  LegacyRef,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
} from "react"
import { useInView } from "react-intersection-observer"

import { Layout } from "../components/Layout"
import { useCardSamples } from "../templates/card-images"

const Page: FC = () => {
  return (
    <Layout pageTitle="">
      <div className="flex flex-col gap-8 md:gap-10 p-2 mb-20">
        <Hero />
        <News />
        <Story />
        <Game />
        <Movie />
        <QandA />
        <Shop />
        <Spec />
        <BeerBanner />
      </div>
      <Menu />
    </Layout>
  )
}

const jump = (selector: string) => {
  scrollTo(selector)
  return false
}

const Hero: FC = () => {
  const url = "https://pomike.tete-a-tete.ink/"

  const shareMsg = `同人誌即売会系ボードゲーム「ポミケ中止」`
  const hashTags = `#ゲムマ #ゲームマーケット #ポミケ中止`

  const twUrl = new URL("https://twitter.com/intent/tweet")
  twUrl.searchParams.set("text", `${shareMsg} ${hashTags}`)
  twUrl.searchParams.set("url", url)

  const fbUrl = new URL("https://www.facebook.com/sharer.php")
  fbUrl.searchParams.set("u", url)

  const lnUrl = new URL("https://social-plugins.line.me/lineit/share")
  lnUrl.searchParams.set("url", url)

  const btnCls =
    "rounded-full bg-current h-10 w-10 sm:h-14 sm:w-14 flex items-center justify-center text-white shadow"

  const iconCls = "h-8 w-8 sm:h-10 sm:w-10"

  return (
    <section className="flex flex-col items-center justify-center gap-4">
      <div id="top" />
      <div className="max-w-5xl relative">
        <StaticImage
          placeholder="none"
          src="../images/ポミケ中止パッケージ背景なし_文字位置下.png"
          alt=""
          className="max-w-3xl"
          width={800}
          loading="eager"
          tracedSVGOptions={{ turdSize: 4000, optCurve: false }}
        />
        <div
          className="absolute flex gap-2 sm:gap-4 md:gap-8"
          style={{ bottom: "8%", right: "10%", fontSize: "0.2vw" }}
        >
          <a
            href={twUrl.toString()}
            className={btnCls}
            style={{ backgroundColor: "#1C9CEA" }}
            onClick={() =>
              window.gtag("event", "share", {
                method: "Twitter",
                item_id: window.location.pathname,
              })
            }
          >
            <IoLogoTwitter className={iconCls} />
          </a>
          <a
            href={fbUrl.toString()}
            className={btnCls}
            style={{ backgroundColor: "#1877F2" }}
            onClick={() =>
              window.gtag("event", "share", {
                method: "Facebook",
                item_id: window.location.pathname,
              })
            }
          >
            <ImFacebook className={iconCls} />
          </a>
          <a
            href={lnUrl.toString()}
            className={btnCls}
            style={{ backgroundColor: "#00C815" }}
            onClick={() =>
              window.gtag("event", "share", {
                method: "LINE",
                item_id: window.location.pathname,
              })
            }
          >
            <RiLineFill className={iconCls} />
          </a>
        </div>
      </div>
      <p className="text-center leading-8 md:text-xl">
        同人誌即売会系カードゲーム「ポミケ中止」
        <br />
        2021年11月21日 ゲームマーケット2021秋 <br className="md:hidden" />
        頒布開始！
      </p>
    </section>
  )
}

type HeaderId =
  | "game"
  | "spec"
  | "news"
  | "story"
  | "movie"
  | "shop"
  | "spec"
  | "qa"

const Section: FC<PropsWithChildren<{ id?: HeaderId }>> = ({
  id,
  children,
}) => {
  return (
    <section className="flex justify-center flex-col items-center relative">
      {id && <SectionHeader id={id} />}
      <div className="max-w-5xl w-full md:w-11/12 lg:w-4/6 gap-2 md:gap-3 flex flex-col bg-white rounded-2xl px-3 md:px-8 pb-8 pt-12 mt-8 bg-opacity-95 relative">
        {children}
      </div>
    </section>
  )
}

const SectionHeader: FC<
  { id: HeaderId } & BaseHTMLAttributes<HTMLHeadElement>
> = ({ children, id, ...props }) => {
  const capId = id.toUpperCase()
  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          sourceInstanceName: { eq: "images" }
          relativeDirectory: { eq: "見出しの文字" }
        }
      ) {
        nodes {
          relativePath
          childImageSharp {
            gatsbyImageData(
              height: 120
              placeholder: TRACED_SVG
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  `)
  const img = getImage(
    data.allFile.nodes.find(
      (n: { relativePath: string }) =>
        n.relativePath === `見出しの文字/${capId}モノクロ.png`
    )
  )
  return (
    <h2 id={id} className="absolute -top-10 z-10" {...props}>
      {img ? <GatsbyImage image={img} alt={capId} /> : <span>{capId}</span>}
    </h2>
  )
}

const CarouselSlide: FC<{
  index: number
  num: number
  image: IGatsbyImageData
}> = ({ index, num, image }) => {
  const check = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (index === 0 && check?.current) check.current.checked = true
  }, [index, check])

  return (
    <>
      <input
        className="carousel-open"
        type="radio"
        id={`carousel-${index}`}
        name="carousel"
        aria-hidden="true"
        hidden={true}
        ref={check}
      />
      <GatsbyImage
        className="carousel-item absolute opacity-0 bg-center"
        style={{ position: undefined }}
        objectFit="contain"
        objectPosition="center"
        image={image}
        alt=""
      />
      <label
        htmlFor={`carousel-${(((index - 1) % num) + num) % num}`}
        className={`control-${index} carousel-control w-10 h-10 ml-2 md:ml-4 absolute cursor-pointer font-bold text-gray-500
        hover:text-white rounded-full opacity-90 bg-white hover:bg-blue-300 z-10 inset-y-0 left-0 my-auto hidden items-center justify-center`}
      >
        <FaAngleLeft className="h-6" />
      </label>
      <label
        htmlFor={`carousel-${(index + 1) % num}`}
        className={`next carousel-control control-${index} w-10 h-10 mr-2 md:mr-4 absolute cursor-pointer hidden items-center justify-center font-bold text-gray-500
        hover:text-white rounded-full opacity-90 bg-white hover:bg-blue-300 text-center z-10 inset-y-0 right-0 my-auto`}
      >
        <FaAngleRight className="h-6" />
      </label>
    </>
  )
}

const CarouselIndicator: FC<{ index: number }> = ({ index }) => {
  return (
    <li className="inline-block mr-3">
      <label
        htmlFor={`carousel-${index}`}
        className="carousel-bullet cursor-pointer block text-4xl text-gray-200 hover:text-blue-300"
      >
        •
      </label>
    </li>
  )
}

const Carousel: FC = () => {
  const images = useCardSamples()
    .map((node) => getImage(node))
    .filter(Boolean) as IGatsbyImageData[]
  return (
    <div className="carousel relative rounded-lg overflow-hidden flex-shrink-0 w-full md:w-2/6">
      <div className="carousel-inner relative overflow-hidden w-full">
        {images.map((img, index: number) => (
          <CarouselSlide
            key={index}
            index={index}
            image={img}
            num={images.length}
          />
        ))}

        <ol className="carousel-indicators">
          {images.map((_, i) => (
            <CarouselIndicator key={i} index={i} />
          ))}
        </ol>
      </div>
    </div>
  )
}

const newsIconPen = (
  <span className="rounded-full bg-pblue-50 h-8 w-8 inline-flex items-center justify-center">
    <FaPenNib className="h-5 w-5 text-white" />
  </span>
)

const newsItems: {
  title: string
  description?: string
  date: string
  url: string
  icon?: JSX.Element
}[] = [
  {
    title: "「ビールと泡」「ポミケ中止」",
    description: `ゲムマ2022春【2日目のみ】参加 の tete-a-tete（テータテート）です。
    頒布物は 1. 新作「ビールと泡」  2. 再版「ポミケ中止」 の予定です！
    「ビールと泡」は プラスチックのビアジョッキに大きめの木製トークンなど、
    コンポーネントが楽しい 変則「髑髏と薔薇」ゲームです。
    【詳細ページこちらへ！】
    木製トークンの調達が当日に間に合うかどうか本当にギリギリでして…
    間に合わなかったらサンプルでのデモンストレーションを行います＼(＾o＾)／
    若干数だけでも頒布できるよう頑張ります。
    また、後日にはなりますが、イエローサブマリンさんに委託をお願いする予定ですので、良ければご利用ください。`,
    date: "2022/4/8",
    url: "https://gamemarket.jp/blog/181653",
  },
  {
    title: "「ポミケ中止」プレイ&紹介動画できました",
    description: `ボードゲーム「ポミケ中止」 簡単なルール説明と共に、プレイの様子をご覧ください♪
    初版完売 ありがとうございました！
    2022年4月24日ゲームマーケット2022春 2日目で再版します♪
    イエサブで委託もあるよ。
    ゲムマ春では、新作「ビールと泡」もお楽しみに！`,
    date: "2022/4/2",
    url: "https://gamemarket.jp/blog/181513",
  },
  {
    title: "「ポミケ中止」再版 イエサブ委託始まりました",
    description: `ゲームマーケット2021秋で完売した 同人誌即売会系ボードゲーム「ポミケ中止」、再版しました！
    次回のゲムマへ向けての再版でしたが、早めに納品されたので…
    先んじてイエローサブマリンさんでの委託・店舗販売 & 通信販売 が開始となりました～
    【イエローサブマリン委託 通販ページ】
    この機会にぜひご利用ください♪`,
    date: "2022/2/11",
    url: "https://gamemarket.jp/blog/181267",
  },
  {
    title: "「ポミケ中止」レポ漫画",
    description: `ゲームマーケット2021秋で頒布した「ポミケ中止」の
レポ漫画を描いて下さった方がおりまして、ブログでの紹介も快諾いただきました…！
乾操さん、ありがとうございました！`,
    date: "2021/12/18",
    url: "https://gamemarket.jp/blog/181162",
  },
  {
    title: "「ポミケ中止」完売御礼！そして実写版PV？",
    description: `ゲームマーケット2021秋で頒布した同人誌即売会系カードゲーム「ポミケ中止」、
通販分も含めて完売いたしました…！ありがとうございました！
Twitterでプレイの感想やレポ漫画をあげてくださった方々もいて、頑張って良かったな～と思うと同時に、アナログゲームという媒体のすごさを感じています。
ところで（？）、ポミケ中止をプレイいただいた方が実写版PVを作って下さったので紹介します。何度見ても面白い。
実はプレイ動画も撮影はされているのですが、編集する時間がなく、私の手元にデータがある状況です…`,
    date: "2021/12/16",
    url: "https://gamemarket.jp/blog/181159",
  },
  {
    title: "「ポミケ中止」完売！通販に在庫少しあります！",
    description: `ゲームマーケット2021秋 2日目（2日目のみ出展）、tete-a-tete(テータテート)にお越しいただいた方々、ありがとうございました！
    「ポミケ中止」会場・BOOTH自家通販は完売しました…！
    書店委託が数点ずつ残っていますので、よろしければご利用ください！
    ◆メロンブックス ◆とらのあな
    ゲムマ初参加・初出展でした…。感想。めっちゃ喋りました…。
    普段コミケに出るときは頒布物の説明とかしないので、わたわたしましたがなんとか。
    「どういうゲームですか？」っていうコミュニケーションがあるんですね…！
    面白そうなギミックあるかなとか、ネタが楽しめそうかなとか、かなり知的なやりとりでエウレカって感じでした。
    「ポミケ中止はパーティゲームですか？それともチキンレース？」というふうに、ゲーム形式について尋ねていただくことがあったのですが、適切な表現が思い付かないので、良い表現があったら教えてください。。。
    あと反省点としてはチラシを用意して行くべきでした…！ 次があれば持って行きます。
    とりあえず冬コミ受かっているので冬コミの原稿がんばります。即売会開催まであと…1ヶ月！`,
    date: "2021/11/21",
    url: "https://gamemarket.jp/blog/181091",
  },
  {
    title: "2日目ウ07「ポミケ中止」",
    description: `ゲームマーケット開催おめでとうございます！
    同人作家となり同人誌即売会で新刊を頒布するゲーム「ポミケ中止」は、
    昨今のなんやかんやでイベントが中止になりまくるなか、”机に新刊を並べて、手渡しのやりとりをしたい！”という想いから作り始めたゲームです。
    イベント会場でサークルスペースに立てる日が再びやってきて嬉しいです。しかもビッグサイト！
    2日目ウ07でお待ちしています！`,
    date: "2021/11/20",
    url: "https://gamemarket.jp/blog/181066",
  },
  {
    title: "「ポミケ中止」の予約受付を開始しました",
    description: `同人誌即売会で新刊を頒布するゲーム「ポミケ中止」の予約受付を開始しました！
      ぜひご利用ください～`,
    date: "2021/10/24",
    url: "https://gamemarket.jp/blog/180126/",
  },
  {
    title: "「ポミケ中止」のアニメPVを作りました",
    description: `ゲームマーケット2021秋の新作「ポミケ中止」のアニメPVを作りました。
      「ポミケ中止」のマスコットキャラクターである、
      ”普段は社会人、真の姿は同人作家の魔法少女いんくちゃん”が動いて喋ります！
      動画後半では試作品でのプレイ写真も載せています。
      ゲームの雰囲気が伝われば幸いです♪`,
    date: "2021/10/23",
    url: "https://gamemarket.jp/blog/180112/",
  },
  {
    title: "「ポミケ中止」のルールブックを公開しました",
    description: `ゲームマーケット2021秋で頒布予定のボードゲーム「ポミケ中止」の
    カード、説明書、化粧箱の入稿を、なんとか予定通り終えました…！
    ルールブックのPDF版を一足先に公開します。→ ポミケ中止説明書
    説明書は、実際にはカードと同じスタンダードサイズ、フルカラー24Pの中綴じ冊子となります。
    同人誌を作るゲームなので、箱を開けたら小さな本が入っているのはなかなか良いんじゃないかな～と今から楽しみです。`,
    date: "2021/9/30",
    url: "https://gamemarket.jp/blog/179933",
  },
  {
    title: "「ポミケ中止」Webサイトができました",
    description: `ゲームマーケット2021秋で頒布予定のボードゲーム「ポミケ中止」の、
    Webサイトが出来ました！ ➡ 「ポミケ中止」Webサイト
    ゲームの概要やカード画像の一部がご覧になれます。
    ようやくカード、説明書、化粧箱それぞれの入稿用データが完成したので、
    今後少しずつゲームの紹介をしていこうと思います！お楽しみに！`,
    date: "2021/9/27",
    url: "https://gamemarket.jp/blog/179924",
  },
  {
    title: "「ポミケ中止」パッケージイラストができました",
    description: `「ポミケ中止」のパッケージイラストがようやく完成しました…！
      「ポミケ中止」は、同人誌即売会へのサークル参加を疑似体験するカードゲームです。
      昨今のあれこれで即売会が中止になってばかりで悔しいので作りました。
      同人活動や即売会への参加を経験したことがある方なら、楽しくプレイいただけると思います。
      パッケージの女の子は、このゲームのマスコットキャラクター「いんくちゃん」です。
      ゲームの宣伝のため…というのは口実で、
      いんくちゃんが動いて喋るところを見たいので、動画を作っています。楽しい。`,
    date: "2021/9/19",
    url: "https://gamemarket.jp/blog/179896",
  },
  {
    title: "ゲームマーケット初参加です",
    description: `はじめまして、サークル「tete-a-tete」の夕凪です。
      サークル名は「テータテート」と読みます。普段は初音ミクの絵を描いたりしています。
      この度、初めてボードゲームを作り、初めてゲームマーケットに参加することにしました。
      同人作家になって新刊を書いたり「新刊下さい！」したり打ち上げしたりするゲームです。
      どうぞよろしくお願いします。`,
    date: "2021/9/18",
    url: "https://gamemarket.jp/blog/179894",
  },
]

const News: FC = () => {
  return (
    <Section id="news">
      <ul className="divide-y divide-gray-100 pr-1 w-full relative max-h-56 overflow-y-auto">
        {newsItems.map((item) => (
          <li key={item.title} className="py-3 md:py-4">
            <div className="flex flex-shrink-0 space-x-3">
              <div className="flex-shrink-0">
                <a href={item.url}>{item.icon ?? newsIconPen}</a>
              </div>
              <div className="flex-1 min-w-0  space-y-1">
                <div className="flex flex-col md:flex-row md:justify-between">
                  <h3 className="font-semibold text-yellow-800">
                    <a href={item.url}>{item.title}</a>
                  </h3>
                  <p className="text-sm text-gray-500">{item.date}</p>
                </div>
                <p className="text-sm text-gray-500 whitespace-nowrap overflow-hidden text-overflow-hidden overflow-ellipsis">
                  <a href={item.url}>{item.description}</a>
                </p>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </Section>
  )
}

const Story: FC = () => {
  return (
    <Section id="story">
      <div className="flex flex-col gap-2">
        <StaticImage
          className="flex-shrink-0"
          src="../images/新刊一冊くださいのいんくちゃん02.png"
          alt=""
          objectFit="scale-down"
          placeholder="none"
          width={800}
        />
        <div>
          <p>あなたはとある同人作家です。</p>
          <p>
            日常生活の合間に執筆に励み、同人誌即売会、通称『ポミケ』に参加する日々でしたが……ポミケが中止だって!?
          </p>
          <p>
            『ポミケ中止』になる前に、新刊をたくさん出して、たくさん買い、最も誉れ高き同人作家になりましょう！
          </p>
        </div>
      </div>
    </Section>
  )
}

const QandA: FC = () => {
  const qaitems = [
    {
      q: "ポミケとコミケは関係ありますか？",
      a: "いいえ！　ポミケとコミケは関係ありません。",
    },
    {
      q: "流行ジャンルの数に頒布停止中の同人誌は含まれますか？",
      a: "含まれます。",
    },
    {
      q: "同人誌を購入するのは１人１冊ですか？",
      a: "基本的にはそうです。ただし、「おつかい」「保存用」を使用した場合はカードの指示に従い2冊まで購入できます。そして「おつかい」「保存用」を同時に使用した場合は合計4冊になります。",
    },
    {
      q: "山札、捨て札がなく、ポミケ中止だけが残っている状態でカードを引くと即座にゲームが終了しますか？",
      a: "いいえ。捨て札がない場合は、ポミケ中止の山が残っていても、山札を作り直しません。その場合は単に引くことができません。",
    },
    {
      q: "終了時に同じジャンルの同人誌を5枚、6枚完成できていた場合の得点はどうなりますか？",
      a: "すみません。ルールブックに書かれていません。5枚 = 19点、6枚 = 21点 になります。",
    },
  ]

  return (
    <Section id="qa">
      <div>
        <dl className="flex flex-col gap-6 divide-pink-900">
          {qaitems.map((i) => (
            <div key={i.q} className="flex flex-col gap-1">
              <dt className="flex">
                <div className="rounded-full bg-pblue-50 h-8 w-8 inline-flex items-center justify-center text-white font-bold mr-2 flex-shrink-0">
                  Q
                </div>
                <p className="text-lg leading-6 mt-1 font-bold">{i.q}</p>
              </dt>
              <dd className="flex">
                <div className="rounded-full h-8 w-8 bg-ppink-50 inline-flex items-center justify-center text-white font-bold mr-2 flex-shrink-0">
                  A
                </div>
                <p className="mt-1">{i.a}</p>
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </Section>
  )
}

const Game: FC = () => {
  return (
    <Section id="game">
      <div className="flex flex-col md:flex-row gap-2 relative items-stretch">
        <Carousel />
        <div className="flex-grow flex flex-col gap-2">
          <p>
            プレイヤーは忙しい日々の中でリソースをやりくりし、それぞれの同人誌を執筆します。
            <br />
            執筆は孤独な作業ですが、時には合同誌を企画したり、一緒に取材旅行に行ったり、税務調査を受けたりしながら、本を完成させて即売会で一堂に会するのです！
          </p>
          <p>
            即売会では自スペースに新刊を並べ、「新刊下さい！」「ありがとうござます！」のやりとりで互いのカードを交換しましょう。
            そうそう、ポミケに参加できない友人のおつかいも忘れずに。
          </p>
          <p>
            イベント後の打ち上げではカードをドラフトして、他サークルとの交流で次作のネタを仕入れよう！
            <br />
            しかし…いつまでもあると思うな即売会。せっかく書いた新刊も、ポミケが無ければ頒布できない！？「ポミケ中止」のカードを引くのは誰だ！？
          </p>
          <p>
            同人誌即売会を少しでも知っていれば盛り上がること間違いなし。締め切りに追われるリアルな焦燥感、同人活動での悲喜こもごもを体感して下さい！
          </p>
          <div className="flex-grow" />
          <p className="self-end">
            <a
              onClick={() =>
                window.gtag("event", "page_view", {
                  page_location:
                    "https://pomike.tete-a-tete.ink/ポミケ中止ルールブック.pdf",
                  page_title: "ポミケ中止ルールブック PDF",
                })
              }
              download="ポミケ中止ルールブック.pdf"
              href="/manual.pdf"
              className="inline-flex items-center px-2.5 py-1.5 border border-transparent text rounded font-bold text-white bg-pblue-50 hover:bg-pblue-70 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pblue-50"
            >
              ゲームルール PDF
            </a>
          </p>
          {/* TODO: ゲーム中の写真 */}
        </div>
      </div>
    </Section>
  )
}

const VideoIFrame: FC<{ videoId: string }> = ({ videoId }) => {
  const { ref: viewRef, inView } = useInView()
  const iframeRef: LegacyRef<HTMLIFrameElement> = useCallback(
    (node: HTMLIFrameElement | null) => {
      if (!inView) return
      if (!node || node.src.includes("youtube.com/embed")) return
      node.src = "https://www.youtube.com/embed/" + videoId
    },
    [videoId, inView]
  )

  return (
    <div
      ref={viewRef}
      className="relative h-0 overflow-hidden"
      style={{ paddingBottom: "56.25%", paddingTop: "30px" }}
    >
      <iframe
        ref={iframeRef}
        className="absolute top-0 left-0 w-full h-full"
        width="560"
        height="315"
        loading="lazy"
        src=""
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </div>
  )
}

const MovieHeader: FC<PropsWithChildren<unknown>> = ({ children }) => (
  <h3 className="text-xl md:text-2xl border-b-4 border-pyellow-50 mb-2">
    {children}
  </h3>
)

const Movie: FC = () => {
  return (
    <Section id="movie">
      <div className="flex flex-col gap-10 text-center">
        <div>
          <MovieHeader>全動画プレイリスト</MovieHeader>
          <VideoIFrame videoId="videoseries?list=PLekAwYNtf1CtwditGLBnRulx-lYKpnEcm" />
        </div>
      </div>
    </Section>
  )
}

const Shop: FC = () => {
  return (
    <Section id="shop">
      <div className="flex flex-col gap-6">
        <div className="flex gap-2 items-center">
          <a href="https://gamemarket.jp/booth/4139">
            <StaticImage
              className="shadow-md"
              src="https://image.gamemarket.jp/ogp.png"
              alt="ゲームマーケット"
              height={80}
            />
          </a>
          <span>2021秋 2日目 ブース番号: ウ-07</span>
        </div>
        <div className="flex flex-col md:flex-row gap-2 md:items-center">
          <a href="https://shop.yellowsubmarine.co.jp/products/detail/84974">
            <StaticImage
              className="shadow-md"
              alt="イエローサブマリン"
              src="../images/banners/yello-submarine.png"
              height={40}
            />
          </a>
          <div>各店舗及びオンラインショップ</div>
        </div>
        <div className="flex flex-col md:flex-row gap-2 md:items-center">
          <a href="https://ec.toranoana.jp/tora_r/ec/item/040030938768/">
            <StaticImage
              className="shadow-md"
              alt="とらのあな"
              src="../images/banners/tora_bn.gif"
              height={40}
            />
          </a>
          <div>(完売しました)</div>
        </div>
        <div className="flex flex-col md:flex-row gap-2 md:items-center">
          <a href="https://www.melonbooks.co.jp/detail/detail.php?product_id=1122115">
            <StaticImage
              className="shadow-md"
              src="../images/banners/melon_200x40.gif"
              height={40}
              alt="メロンブックス"
            />
          </a>
          <div>(完売しました)</div>
        </div>
        <div className="flex flex-col md:flex-row gap-2 md:items-center">
          <a href="https://tete-a-tete.booth.pm/items/3304911">
            <StaticImage
              alt="BOOTH"
              className="shadow-md"
              src="../images/banners/booth.png"
            />
          </a>
          <div>2022-08-12: 在庫復活しました</div>
        </div>
      </div>
    </Section>
  )
}

const Spec: FC = () => {
  const Item: FC<PropsWithChildren<unknown>> = ({ children }) => (
    <div className="py-2 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
      {children}
    </div>
  )
  const Dt: FC<PropsWithChildren<unknown>> = ({ children }) => (
    <dt className="font-bold">{children}</dt>
  )
  const Dd: FC<PropsWithChildren<unknown>> = ({ children }) => (
    <dd className="text-gray-900 sm:mt-0 sm:col-span-2">{children}</dd>
  )

  return (
    <Section id="spec">
      <div className=" overflow-hidden">
        <div className="sm:border-t sm:border-gray-200 px-4 sm:p-0">
          <dl className="sm:divide-y sm:divide-gray-300">
            <Item>
              <Dt>タイトル</Dt>
              <Dd>ポミケ中止</Dd>
            </Item>
            <Item>
              <Dt>プレイ時間</Dt>
              <Dd>20分～40分</Dd>
            </Item>
            <Item>
              <Dt>プレイ人数</Dt>
              <Dd>2～4人</Dd>
            </Item>
            <Item>
              <Dt>対象年齢</Dt>
              <Dd>8歳以上</Dd>
            </Item>
            <Item>
              <Dt>内容物</Dt>
              <Dd>
                <ul className="list-disc ml-4">
                  <li>化粧箱</li>
                  <li>カード52種 61枚 (スタンダードサイズ)</li>
                  <li>説明書 (フルカラー24p・カードサイズ)</li>
                </ul>
              </Dd>
            </Item>
            <Item>
              <Dt>頒布価格</Dt>
              <Dd>
                イベント価格: 2000円 <br />
                Booth通販価格: 2500円 <br />
                委託価格: 2970円
              </Dd>
            </Item>
            <Item>
              <Dt>ゲームデザイン</Dt>
              <Dd>
                ぐる,{" "}
                <a
                  className="text-yellow-800"
                  href="https://twitter.com/yuunagi_show"
                >
                  夕凪ショウ
                </a>
              </Dd>
            </Item>
            <Item>
              <Dt>グラフィック</Dt>
              <Dd>
                <a
                  className="text-yellow-800"
                  href="https://twitter.com/yuunagi_show"
                >
                  夕凪ショウ
                </a>
                , 六珈
              </Dd>
            </Item>
            <Item>
              <Dt>サークル</Dt>
              <Dd>
                <a
                  href="https://yuunagi-show.hatenablog.com/"
                  className="text-yellow-800"
                >
                  tete-a-tete (テータテート)
                </a>
              </Dd>
            </Item>
          </dl>
        </div>
      </div>
    </Section>
  )
}

const Menu: FC = () => {
  const links = [
    { link: "news" },
    { link: "story" },
    { link: "game" },
    { link: "movie" },
    { link: "qa", label: "Q&A" },
    { link: "shop" },
    { link: "spec" },
  ]

  return (
    <div className="fixed bottom-0 w-full z-30 md:px-2">
      <div
        className="max-w-5xl w-full md:w-11/12 lg:w-4/6 rounded-t-2xl px-4 py-5 mx-auto
        hidden md:flex justify-between items-stretch divide-x divide-pblue-80
        text-2xl font-extrabold leading-none bg-white border-t-2 border-pyellow-50 text-pblue-80
        text-center"
      >
        {links.map(({ link, label }) => (
          <a
            key={link}
            className="flex-1 text-center"
            href={`#${link}`}
            onClick={() => jump(`#${link}`)}
          >
            {label ?? link.toUpperCase()}
          </a>
        ))}
        <a className="flex-shrink-0 pl-4 " href="#top">
          <BiArrowToTop className="h-6" />
        </a>
      </div>
      <div className="border-t-2 border-pyellow-50 md:hidden relative">
        <Disclosure>
          {({ close }) => (
            <>
              <Transition
                enter="transition duration-200 ease-out"
                enterFrom="transform opacity-0"
                enterTo="transform opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform opacity-100"
                leaveTo="transform opacity-0"
              >
                <Disclosure.Panel className="grid grid-cols-2 bg-white text-center items-center gap-6 px-4 text-2xl font-semibold text-pblue-80 leading-none py-4 border-b">
                  {links.map(({ link, label }) => (
                    <a
                      key={link}
                      href={`#${link}`}
                      onClick={() => {
                        jump(`#${link}`)
                        close()
                        ;(document.activeElement as HTMLAnchorElement)?.blur()
                      }}
                    >
                      {label ?? link.toUpperCase()}
                    </a>
                  ))}
                </Disclosure.Panel>
              </Transition>
              <div className="w-full flex items-center bg-white m-0">
                <Disclosure.Button
                  tabIndex={-1}
                  className="py-2 pl-8 text-2xl w-full font-bold text-center bg-white text-black"
                >
                  MENU
                </Disclosure.Button>
                <a
                  className="flex-shrink-0 px-1 items-center"
                  href="#top"
                  onClick={() => {
                    jump("#top")
                    close()
                    ;(document.activeElement as HTMLAnchorElement)?.blur()
                  }}
                >
                  <BiArrowToTop className="h-8 w-8" />
                </a>
              </div>
            </>
          )}
        </Disclosure>
      </div>
    </div>
  )
}

const BeerBanner: FC = () => {
  return (
    <section className="flex justify-center flex-col items-center relative">
      <a
        href="https://beer.tete-a-tete.ink/"
        className="flex items-center bg-bgreen-50 max-w-5xl w-full md:w-11/12 lg:w-4/6 shadow-lg pr-2 md:pr-6 rounded hover:ring-offset-bgreen-50"
      >
        <p className="flex-grow font-bold text-xl md:text-2xl text-center text-white">
          新作「ビールと泡」頒布開始！
        </p>
        <StaticImage
          alt="ビールと泡"
          src="../images/banners/bannar_white.png"
          className="flex-end"
          height={128}
        />
      </a>
    </section>
  )
}

export default Page
